<template>
  <div class="gamepagelogo">
    <img :src="`/img/logo_${game}.png`" />
  </div>
  <div class="gamepage">
    <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${games[game].video}`" :title="`${game}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <div class="container"><div class="item" :key="store.link" v-for="store in games[game].stores" ><a :href="store.link"><img :src="store.img"></a></div></div>
  </div>
</template>

<script>
export default {
    props : { game : String },
    data() {
        return { games : { nimbus : { video : 'yneK3xPtCKw', stores : [ 
                            { link : 'https://store.steampowered.com/app/50000/Nimbus/', img : '/img/logo_steam.png' } ] },
                           snakebird : { video : 'WJJa3hMbs4s', stores : [ 
                            { link : 'https://store.steampowered.com/app/357300/Snakebird/', img : '/img/logo_steam.png' },
                            { link : 'https://play.google.com/store/apps/details?id=com.NoumenonGames.SnakeBird_Touch', img : '/img/logo_playstore.png' },
                            { link : 'https://apps.apple.com/us/app/snakebird/id1087075743', img : '/img/logo_appstore.png' } ] },
                           snakebird_primer : { video : 'CZAszCORHVw', stores : [ 
                            { link : 'https://store.steampowered.com/app/1014140/Snakebird_Primer/', img : '/img/logo_steam.png' },
                            { link : 'https://play.google.com/store/apps/details?id=com.NoumenonGames.SnakeBird_Touch_Primer', img : '/img/logo_playstore.png' },
                            { link : 'https://apps.apple.com/us/app/snakebird-primer/id1440486512', img : '/img/logo_appstore.png' } ] },
                           snakebird_complete : { video : '-W3AJLNzHfI', stores : [ 
                            { link : 'https://www.nintendo.com/us/store/products/snakebird-complete-switch/', img : '/img/logo_nintendoeshop.png' } ] }
                          } };
    }
}
</script>

<style lang="scss" scoped>
  .gamepage
  {
      .container
      {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .item
        {
            width: 100%;
            max-width: 25%;
            margin: 0 auto;
            img
            {
                margin: auto;
                width: 100%;
            }
        }
      }
    ul
    {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
      li
      {
        margin: 40px;
        list-style: none;
        a
        {
            img
            {
                margin: auto;
                width: 100%;
            }
        }
      }
    }
  }
</style>
